import { useState, useContext, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion"
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Row, Col, Image, Badge, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { useAppState } from '../hooks/useApp';
import { useGlobalState } from '../hooks/useInitialization';
import InputMask from "react-input-mask"
import { Formik } from "formik"
import NotyfContext from "../contexts/NotyfContext";
import { friendRequest, blockRequest } from "../pages/protected/Networking.js"
import PageLoader from '../components/PageLoader.js';
import defaultAvatar from '../assets/img/default-avatar-profile-picture-male-icon.png'
import { countStandAloneNumbers } from './Functions.js';

export const NetworkingConfirmationModal = ({ pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, setTotalContacts }) => {
    const { socket, modalConfirmation, setModalConfirmation, confirmationProps, setConfirmationProps } = useAppState()

    function handleClose() {
        setModalConfirmation(false);
        setConfirmationProps({});
    }

    const confirmationHandler = (e) => {
        switch (confirmationProps.type) {
            case 'remove':
                friendRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            case 'block':
                blockRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            case 'unblock':
                blockRequest(e, confirmationProps.status, confirmationProps.user_id, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, setTotalContacts, pageDataItem, setPageDataItem)
                setConfirmationProps({})
                setModalConfirmation(false)
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            show={modalConfirmation}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{confirmationProps.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{confirmationProps.description}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Voltar
                </Button>
                <Button variant="primary" onClick={confirmationHandler}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export const QRCodeModal = ({ pageDataItem, showModal, setShowModal }) => {
    const { dataApplication } = useGlobalState()
    const [name, setName] = useState()
    const { socket, dispatch } = useAppState();
    const location = useLocation();
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [updateUserContact, setUpdateUserContact] = useState(false)
    const imageLink = dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/"

    function handleClose() {
        setShowModal(false);
    }

    const handleSubmitLead = (values, actions) => {
        setUpdateUserContact(true)
        socket.emit('editLeadCollection', { json: JSON.stringify(values) });
    }

    useEffect(() => {
        if (socket) {
            socket.on('editLeadCollection', (result) => {
                setUpdateUserContact(false)
                handleClose()
                notyf.open({
                    type: "success",
                    message: 'Campos atualizados com sucesso!',
                    ripple: true,
                    dismissible: true,
                });
            });

            return () => {
                socket.off('editLeadCollection');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    console.log(pageDataItem);

    if (pageDataItem) {
        switch (pageDataItem.type) {
            case 'interest_point':
                return (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        fullscreen={'sm-down'}
                        animation={true}
                        className="pointsOfInterestCardQr"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Novo ponto de interesse</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="qrcodeItemBody text-center">
                            <Row className="justify-content-center">
                                <div className="qrcodeItemCard">
                                    <AnimatePresence>
                                        <motion.div
                                            className={`imageContainer mb-3 ${isLoaded ? '' : 'not-collected'}`}
                                            initial={{ filter: 'grayscale(100%) blur(2px)' }}
                                            animate={isLoaded ? { filter: 'grayscale(0%) blur(0px)' } : {}}
                                            transition={{ delay: 0.5, duration: 1.5 }}>
                                            <img
                                                src={`${dataApplication.customizacao.server.imageServer}/plataforma-eventos/${dataApplication.event_id}/${pageDataItem.message.json.image}`}
                                                alt="points of interests images"
                                                onLoad={() => setIsLoaded(true)}
                                            />
                                        </motion.div>
                                    </AnimatePresence>
                                    <div className="pointsOfInterestDetails">
                                        <h6 className="fw-bold mb-2">
                                            {pageDataItem.message.json.title}
                                        </h6>
                                        <div>
                                            <Badge bg="outline-secondary"> {pageDataItem.message.value}</Badge>
                                        </div>
                                    </div>
                                </div>
                                <Col sm={12} className="px-4 my-auto my-md-4">
                                    <p>Ponto de interesse <b>{pageDataItem.message.json.title}</b> coletado.<br></br>Você aumentou sua pontuação!</p>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal >
                )
            case 'contact':
                return (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        fullscreen={'sm-down'}
                        animation={true}
                        className="qrcodeModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Novo contato</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="qrcodeItemBody text-center">
                            <Row>
                                <div className="qrcodeItemCard">
                                    <Image className="perfil mb-3" src={pageDataItem.message.image !== null && pageDataItem.message.image !== '' ? imageLink + pageDataItem.message.image : defaultAvatar} roundedCircle ></Image>
                                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                        if (field && pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                            if (pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                                if (index === 0) {
                                                    if (!name) {
                                                        setName(pageDataItem.message.json[field.inputID].value)
                                                    }
                                                    return (
                                                        <h6 className="mb-0" key={'h5' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</h6>
                                                    )
                                                } else {
                                                    return (
                                                        <p className="mt-1 mb-0 small" key={'small' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</p>
                                                    )
                                                }
                                            }
                                        }
                                        return null;
                                    })}
                                    {/* <div>
                                        <Button type="button" bg={'primary'} className="my-3 btn-sm" onClick={() => navigate(`/participantes/${pageDataItem.message.user_id}${location.search}`)} >Ver perfil</Button>
                                    </div> */}
                                </div>
                                <Col sm={12} className="px-4 my-auto my-md-4">
                                    <p>Você adicionou <b>{name}</b> na lista de contatos.<br></br><br></br>Agora você pode visualizar todas suas informações e interagir com ele!</p>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Voltar</Button>
                        </Modal.Footer>
                    </Modal>
                )
            case 'lead':
                return (
                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        fullscreen={'sm-down'}
                        animation={true}
                        className="qrcodeModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Novo contato</Modal.Title>
                        </Modal.Header>
                        <Formik
                            validationSchema={Yup.object().shape({
                                contato: Yup.string()
                                    .max("255", "Máximo de 255 caracteres"),
                                observacao: Yup.string()
                                    .max(255, `Máximo de 1000 caracteres`)
                            })}
                            onSubmit={handleSubmitLead}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize
                            initialValues={{
                                contato: '',
                                observacao: '',
                                id: pageDataItem.message.user_id
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                                dirty,
                                isSubmitting,
                                setSubmitting,
                                setFieldValue,
                                setFieldError
                            }) => (
                                <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column h-100">
                                    <Modal.Body className="qrcodeItemBody text-center">
                                        <Row>
                                            <div className="qrcodeItemCard">
                                                <Image className="perfil mb-3" src={pageDataItem.message.image !== null && pageDataItem.message.image !== '' ? imageLink + pageDataItem.message.image : defaultAvatar} roundedCircle ></Image>
                                                {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (field && pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                                        if (index === 0) {
                                                            return (
                                                                <h6 className="mb-0" key={'h5' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</h6>
                                                            )
                                                        } else {
                                                            return (
                                                                <p className="mt-1 mb-0 small" key={'small' + field.inputID}>{pageDataItem.message.json[field.inputID].value}</p>
                                                            )
                                                        }
                                                    }
                                                    return null;
                                                })}
                                                {/* <div>
                                                    <Button type="button" bg={'primary'} className="my-3 btn-sm" onClick={() => navigate(`/coleta-de-lead/${pageDataItem.message.user_id}${location.search}`)}>Ver perfil</Button>
                                                </div> */}
                                            </div>
                                            <Col sm={12} className="px-4 my-auto my-md-4">
                                                {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (field && pageDataItem.message.json && pageDataItem.message.json[field.inputID]) {
                                                        if (index === 0) {
                                                            return (
                                                                <p>Você adicionou <b>{pageDataItem.message.json[field.inputID].value}</b> na lista de contatos.<br></br><br></br>Agora você pode visualizar todas suas informações na página de coleta de lead</p>
                                                            )
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            </Col>
                                            <Row className="mt-4 mx-0">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Contato</Form.Label>
                                                    <InputMask
                                                        placeholder={"(00) 0.0000-0000"}
                                                        mask={"(99) 9.9999-9999"}
                                                        value={values.contato}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.contato}
                                                        isValid={touched.contato && !errors.contato}
                                                    >
                                                        {(inputProps) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                type="text"
                                                                name={"contato"}
                                                            />
                                                        )}
                                                    </InputMask>
                                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contato}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12} className="mb-3">
                                                    <Form.Label>Observação</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        type="text"
                                                        name="observacao"
                                                        value={values.observacao}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.observacao}
                                                        isValid={touched.observacao && !errors.observacao}
                                                    />
                                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.observacao}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>

                                        <motion.div
                                            key="accessCodeGranted2"
                                            className="text-center"
                                            initial={{ height: 0, opacity: 0 }}
                                            animate={{ height: 'auto', opacity: 1 }}
                                            exit={{ height: 0, opacity: 0 }}
                                            transition={{ duration: 0.5 }}
                                            style={{ overflow: 'hidden' }}
                                        >
                                            <Button
                                                variant="primary"
                                                id="submitButton"
                                                type={'submit'}
                                                disabled={updateUserContact}
                                            >
                                                {!updateUserContact ? 'Salvar' : <PageLoader color="#fff" width="25" padding={0} />}
                                            </Button>
                                        </motion.div>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                )
            default:
                return ""
        }
    }
}