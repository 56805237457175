import { useState, useEffect } from 'react'
import { Button, Col, Row, InputGroup, Badge } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose, faMessage, faStar, faPhotoFilm } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'
import { useAppState } from '../../hooks/useApp.js'
import { useGlobalState } from '../../hooks/useInitialization.js'
import PageLoader from '../../components/PageLoader.js'
import { SocialMidiaIcons, TextWithLineBreaks, cleanUpSpecialChars } from "../../components/Functions.js"
import "react-image-gallery/styles/css/image-gallery.css";

const Exhibitors = () => {
    const { dataApplication } = useGlobalState();
    const { socket } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)

    //Search function
    const search = (data) => {
        if (searchQuery.trim() !== '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
            const flattenAndCleanArray = (arr) => {
                return arr.map(item => cleanUpSpecialChars(item.toLowerCase())).join(' ');
            };
            let newData = data.filter(category => {
                const searchableFields = [
                    cleanUpSpecialChars(category.json.name.toLowerCase()),
                    cleanUpSpecialChars(category.json.description ? category.json.description.toLowerCase() : ''),
                    category.json.categories ? flattenAndCleanArray(category.json.categories) : ''

                ];
                return searchableFields.some(field => field.includes(lowercaseQuery));
            })
            return newData
        } else {
            return data
        }
    }

    useEffect(() => {
        if (pageData) {
            let newData = search(pageDataComplete)
            setPageData(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    useEffect(() => {
        if (socket && !pageDataItem) {
            socket.on('pageDataExhibitor', (response) => {
                if (id && response.id) {
                    setPageDataItem(response);
                } else {
                    setPageData(response);
                    setPageDataComplete(response)
                }
            });

            socket.emit('pageDataExhibitor', { page: 'exhibitors', id: id });

            return () => {
                socket.off('pageDataExhibitor');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, id]);

    return (
        <motion.div className="container exhibitorContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <AnimatePresence mode="wait" initial={false}>
                {id > 0 ? (
                    <motion.div key={'itemA'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <header className="pageHeader">
                            <Button type="button" className="backButton" onClick={(() => navigate(`/expositores${location.search}`))}>
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Button>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ms-2">
                                    <li className="breadcrumb-item"><h5 onClick={(() => navigate(`/expositores${location.search}`))}>Expositores</h5></li>
                                    <li className="breadcrumb-item"><h5>Detalhes</h5></li>
                                </ol>
                            </nav>
                        </header>
                        {(!pageDataItem) ? (
                            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                            </motion.div>
                        ) : (
                            <>
                                <div className='exhibitorItemHeader'>
                                    <div className="exhibitorRankingContainer">
                                        <FontAwesomeIcon icon={pageDataItem.favorite === 1 ? faStar : faStarRegular} onClick={(e) => setFavorite(e, pageDataItem, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem)} />
                                    </div>
                                    <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + pageDataItem.json.logo} alt="exhibitor logo"></img>
                                </div>
                                <Row className="exhibitorItemBody">
                                    <Col sm={12}>
                                        <h6 className="mb-0">
                                            {pageDataItem.json.name}
                                        </h6>
                                        <p className="small mt-1 mb-2">{pageDataItem.json.description}</p>
                                    </Col>
                                    <Col sm={12} className="mb-3">
                                        {pageDataItem.json.social_media && pageDataItem.json.social_media.length > 0 && <div>
                                            {pageDataItem.json.social_media.map((element, index) => (
                                                <SocialMidiaIcons key={'icons' + index} element={element} index={index} />
                                            ))}
                                        </div>}
                                    </Col>
                                    <Col sm={12} className="my-3">
                                        <Button variant="primary" className="me-2"><FontAwesomeIcon size={'lg'} icon={faMessage} /></Button>
                                    </Col>
                                    <Row className="mx-0">
                                        <hr className="mb-2 mt-0"></hr>
                                    </Row>
                                </Row>
                                <Row>
                                    <Col sm={12} className="mt-3">
                                        <h6 className="fw-bold">Sobre</h6>
                                        <small><TextWithLineBreaks text={pageDataItem.json.about}></TextWithLineBreaks></small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="mt-3">
                                        <h6 className="fw-bold">Localização</h6>
                                        <small><TextWithLineBreaks text={pageDataItem.json.location}></TextWithLineBreaks></small>
                                        <small><TextWithLineBreaks text={pageDataItem.json.locationDescription}></TextWithLineBreaks></small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="mt-3">
                                        <h6 className="fw-bold">Mídia</h6>
                                        {pageDataItem.json.media && pageDataItem.json.media.length > 0 ? (
                                            <>
                                                {(Array.isArray(pageDataItem.json.media)) && pageDataItem.json.media.length > 0 && <Col sm={12} className="my-3">
                                                    <ImageGallery items={pageDataItem.json.media} className="mt-3" />
                                                </Col>}
                                            </>
                                        ) : (
                                            <Row className="mb-3">
                                                <Col className="text-center mt-4" md={12}>
                                                    <FontAwesomeIcon icon={faPhotoFilm} className="me-1 fileIcon" />
                                                </Col>
                                                <Col className="text-center mt-4" sm={12}>
                                                    <p className="opacity-75">Nenhuma mídia encontrada</p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </motion.div>
                ) : (
                    <motion.div key={'listB'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <header className="pageHeader">
                            <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                                <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                            </Button>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb ms-2">
                                    <li className="breadcrumb-item"><h5>Expositores</h5></li>
                                </ol>
                            </nav>
                            {/* <Button type="button" className="backButton btn-sm ms-auto" onClick={() => ("")}>
                                <FontAwesomeIcon icon={faSliders} style={{ transform: 'rotate(90deg)' }} size={"lg"} />
                            </Button> */}
                        </header>
                        <Row className="mb-3">
                            <Col sm={12}>
                                <InputGroup className="mb-3">
                                    <motion.input
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                        exit={{ opacity: 0 }}
                                        className="form-control"
                                        placeholder="Pesquisar..."
                                        aria-label="search"
                                        aria-describedby="search"
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                    />
                                    <AnimatePresence>
                                        {searchQuery !== '' && (
                                            <motion.div
                                                layout
                                                id="search"
                                                className="input-group-text"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <FontAwesomeIcon className="primaryColorT" icon={faClose} onClick={() => setSearchQuery('')} />
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </InputGroup>
                            </Col>
                        </Row>
                        {(!pageData) ? (
                            <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                                <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                            </motion.div>
                        ) : (
                            <>
                                {(Array.isArray(pageData)) && pageData.length > 0 ? (
                                    <Row>
                                        {pageData.map((item, index) => (
                                            <Col md={6} sm={12} lg={4} key={'ExhibitorCard' + index} >
                                                <ExhibitorCard item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} origin={'exhibitors'}></ExhibitorCard>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <Row className="mb-3">
                                        <Col className="text-center mt-4" sm={12}>
                                            <p className="opacity-75">Nenhum expositor encontrado</p>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </motion.div>
                )
                }
            </AnimatePresence >
        </motion.div >
    )
}

export const ExhibitorCard = ({ item, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem, origin }) => {
    const { dataApplication } = useGlobalState();
    const { socket, dispatch } = useAppState()
    const navigate = useNavigate();
    const location = useLocation()

    const handleCardClick = (item) => {
        if (origin === 'exhibitors') {
            navigate(`/expositores/${item.id}${location.search}`, { state: { item: item } });
        } else {
            navigate(`/expositores/${item.id}${location.search}`);
        }
        dispatch({ type: 'updatePageDataItem' });
    }

    return (
        <div className="card pointer mb-3" onClick={() => handleCardClick(item)}>
            <img className="card-img-top" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + item.json.logo} alt="exhibitor logo"></img>
            <div className="card-body">
                <h6 className="card-title mb-0">
                    {item.json.name}
                    <FontAwesomeIcon className={`iconsHover ms-2 ${item.favorite === 1 ? 'primaryColorT' : ''}`} size={'sm'} icon={faStar} onClick={(e) => setFavorite(e, item, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem)} />
                </h6>
                {item.json.description && <p className="small mt-1 mb-0">{item.json.description}</p>}
                <div>
                    {item.json.categories && item.json.categories.map((element, index) => (
                        <Badge key={'category' + index} className="me-2 mt-2" bg={'outline-secondary'}>{element}</Badge>
                    ))}
                </div>
            </div>
        </div>
    )
}

export function setFavorite(e, item, pageData, setPageData, socket, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem) {
    e.stopPropagation();
    if (pageDataItem) {
        //if element is open
        setPageDataItem(prevState => ({
            ...prevState,
            favorite: prevState.favorite === 0 ? 1 : 0
        }));
    }
    if (pageData) {
        if (pageData.exhibitors) {
            //For external pages
            const updatedEvents = pageData.exhibitors.map(exhibitor => {
                if (exhibitor.id === item.id) {
                    return {
                        ...exhibitor,
                        favorite: exhibitor.favorite === 0 ? 1 : 0

                    };
                }
                return exhibitor;
            })
            setPageData(prevState => ({
                ...prevState,
                exhibitors: updatedEvents
            }));
        } else {
            //Within the pages
            const updatedEvents = pageData.map(exhibitor => {
                if (exhibitor.id === item.id) {
                    return {
                        ...exhibitor,
                        favorite: exhibitor.favorite === 0 ? 1 : 0

                    };
                }
                return exhibitor;
            })
            setPageData(updatedEvents);
            if (pageDataComplete) {
                const updatedEvents2 = pageDataComplete.map(exhibitor => {
                    if (exhibitor.id === item.id) {
                        return {
                            ...exhibitor,
                            favorite: exhibitor.favorite === 0 ? 1 : 0

                        };
                    }
                    return exhibitor;
                })
                setPageDataComplete(updatedEvents2);
            }
        }
    }
    socket.emit('favoriteExhibitor', {
        id: item.id
    });
}

export default Exhibitors