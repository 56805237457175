import { useEffect, useRef } from "react";

function useOuterClick(callback) {
  const callbackRef = useRef();
  const innerRef = useRef();

  function handleClick(e) {
    if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
      callbackRef.current(e);
    }
  }

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const elements = document.querySelectorAll('.main');
    elements[0].addEventListener("click", handleClick);
    return () => elements[0].removeEventListener("click", handleClick);

  }, []);

  return innerRef;
}

export default useOuterClick;
