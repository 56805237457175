import { useEffect } from "react";

function useSettingsState(key, initialValue) {

  // useEffect(() => {
  //   // Set data attribute on body element
  //   document.body.dataset[key] = value;

  //   // Replace style sheet if dark theme gets toggled
  //   if (key === "theme") {
  //     const theme = value === "dark" ? "dark" : "light";
  //     const stylesheet = document.querySelector(".js-stylesheet");
  //     if (stylesheet !== null) {
  //       stylesheet.setAttribute("href", `/css/${theme}.css`);
  //     }
  //   }
  // }, [value, key]);

  return [key, initialValue];
}

export default useSettingsState;
