import { useState, useEffect } from 'react'
import { Button, Col, Row, InputGroup, } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp.js'
import { useGlobalState } from '../../hooks/useInitialization.js'
import PageLoader from '../../components/PageLoader.js'
import { cleanUpSpecialChars, redirectURL } from "../../components/Functions.js"
import "react-image-gallery/styles/css/image-gallery.css";

const Sponsors = () => {
    const { dataApplication } = useGlobalState();
    const { socket } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)

    //Search function
    const search = (data) => {
        if (searchQuery.trim() !== '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
            let newData = data.map(tier => {
                const matchingElements = tier.elements.filter(element => {
                    const categoryWords = element.json.category.toLowerCase().split(' ').map(cleanUpSpecialChars);
                    const searchableFields = [
                        cleanUpSpecialChars(element.json.name.toLowerCase()),
                        ...categoryWords
                    ];
                    return searchableFields.some(field => field.includes(lowercaseQuery));
                });
                return {
                    ...tier,
                    elements: matchingElements
                };
            }).filter(tier => tier.elements.length > 0);
            return newData
        } else {
            return data
        }
    }

    useEffect(() => {
        if (pageData) {
            let newData = search(pageDataComplete)
            setPageData(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    useEffect(() => {
        if (socket) {
            socket.on('pageDataSponsor', (response) => {
                console.log(response)
                if (id && response.id) {
                    setPageDataItem(response);
                } else {
                    setPageData(response);
                    setPageDataComplete(response)
                }
            });
            socket.emit('pageDataSponsor', { page: 'sponsors', id: id });
            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataSponsor');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <motion.div className="container sponsorContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Patrocinadores</h5></li>
                    </ol>
                </nav>
                {/* <Button type="button" className="backButton btn-sm ms-auto" onClick={() => ("")}>
                    <FontAwesomeIcon icon={faSliders} style={{ transform: 'rotate(90deg)' }} size={"lg"} />
                </Button> */}
            </header>
            <Row className="mb-3">
                <Col sm={12}>
                    <InputGroup className="mb-3">
                        <motion.input
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5 } }}
                            exit={{ opacity: 0 }}
                            className="form-control"
                            placeholder="Pesquisar..."
                            aria-label="search"
                            aria-describedby="search"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                        <AnimatePresence>
                            {searchQuery !== '' && (
                                <motion.div
                                    layout
                                    id="search"
                                    className="input-group-text"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                    exit={{ opacity: 0 }}
                                >
                                    <FontAwesomeIcon className="primaryColorT" icon={faClose} onClick={() => setSearchQuery('')} />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </InputGroup>
                </Col>
            </Row>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <>
                            {pageData.map((item, index) => (
                                <SponsorCard key={'SponsorCard' + index} index={index} item={item} pageData={pageData} setPageData={setPageData} pageDataComplete={pageDataComplete} setPageDataComplete={setPageDataComplete} pageDataItem={pageDataItem} setPageDataItem={setPageDataItem} />
                            ))}
                        </>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum patrocinador encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div>
    )
}

export const SponsorCard = ({ index, item, pageData, setPageData, pageDataComplete, setPageDataComplete, pageDataItem, setPageDataItem }) => {
    const { dataApplication } = useGlobalState();

    return (
        <Row>
            <h5 className="primaryColorT fw-bold mb-3">{index + parseInt(1) + '. ' + item.tier}</h5>
            {item.elements.map((subItem, index) => (
                <Col md={subItem.column / 2} className={`col-${subItem.column}`} key={'cardSubItem' + index} onClick={() => redirectURL(true, subItem.json.link ? subItem.json.link : 'https://google.com.br')} >
                    <div className="card pointer mb-3">
                        <img className="card-img-bottom" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + subItem.json.image} alt="sponsor logo"></img>
                    </div>
                </Col>
            ))}
            {index + parseInt(1) < pageData.length && <Row className="mx-0">
                <hr className="mb-4 mt-0"></hr>
            </Row>}
        </Row>
    )
}

export const SponsorCardHome = ({ item }) => {
    const { dataApplication } = useGlobalState();

    return (
        <div className="card pointer mb-3" onClick={() => redirectURL(true, item.json.link ? item.json.link : 'https://google.com.br')} >
            <img className="card-img-bottom" src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + item.json.image} alt="sponsor logo"></img>
        </div>
    )
}

export default Sponsors