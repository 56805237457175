import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async"
import { InitializationProvider } from './contexts/InitializationContext';
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/Base.scss'
import './assets/css/Notyf.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <InitializationProvider>
        <App />
      </InitializationProvider>
    </HelmetProvider>
  </BrowserRouter >
)

reportWebVitals()
serviceWorkerRegistration.register();