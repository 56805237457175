import { ThreeDots } from 'react-loader-spinner'

const PageLoader = ({ color, width, padding = 2 }) => {
    return (
        <>
            <div className={`loaderScreen py-${padding}`}>
                <ThreeDots
                    visible={true}
                    height={width}
                    width={width}
                    color={color}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>
    )
}

export default PageLoader