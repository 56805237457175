
import { useState, useRef } from 'react'
import { Row, Col, Button, Dropdown } from 'react-bootstrap'
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from '../../hooks/useInitialization.js';
import { faAngleLeft, faEllipsisVertical, faEnvelopeOpen, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp.js';
import PageLoader from '../../components/PageLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import calendarIcon from '../../assets/img/stopwatch_7205867.png'
import announcementIcon from '../../assets/img/propaganda_4336390.png'
import defaultAvatar from '../../assets/img/default-avatar-profile-picture-male-icon.png'

const Notifications = () => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, setUpdateNotifications } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const [pageFetching, setPageFetching] = useState(false)
    const pagination = useRef(0)
    const limit = 25;

    return (
        <motion.div className="container notificationContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader mb-3">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Notificações</h5></li>
                    </ol>
                </nav>
                <Button type="button" className="backButton btn-sm ms-auto" onClick={() => { dispatch({ type: 'notifications', payload: undefined }); setUpdateNotifications((prevState) => prevState + 1) }}>
                    <FontAwesomeIcon icon={faRefresh} size={"lg"} />
                </Button>
            </header>
            {(!appState.notifications) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(appState.notifications)) && appState.notifications.length > 0 ? (
                        <Row>
                            {appState.notifications.map((item, index) => {
                                return (
                                    <Col sm={12} key={'notificationCard2' + index} >
                                        <NotificationCard item={item} origin={2}></NotificationCard>
                                    </Col>
                                )
                            })}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhuma notificação encontrada</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div >
    )
}

export const NotificationsDropdown = ({ setShowNotificationDropdown }) => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, setUpdateNotifications } = useAppState()

    return (
        <>
            <div className="notification-card-header">
                <h5 className='mb-0'>Notificações</h5>
                <Button type="button" className="backButton btn-sm ms-auto" onClick={() => { dispatch({ type: 'notifications', payload: undefined }); setUpdateNotifications((prevState) => prevState + 1) }}>
                    <FontAwesomeIcon icon={faRefresh} size={"lg"} />
                </Button>
            </div>
            <div className="notification-card-body">
                {(!appState.notifications) ? (
                    <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </motion.div>
                ) : (
                    <>
                        {(Array.isArray(appState.notifications)) && appState.notifications.length > 0 ? (
                            <div className="p-3">
                                {appState.notifications.map((item, index) => {
                                    return (
                                        <NotificationCard key={'notificationCard' + item.id} item={item} setShowNotificationDropdown={setShowNotificationDropdown} origin={1}></NotificationCard>
                                    )
                                })}
                            </div>
                        ) : (
                            <Row className="my-3 mx-0">
                                <Col className="text-center mt-4" sm={12}>
                                    <p className="opacity-75">Nenhum notificação encontrada</p>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export const NotificationCard = ({ item, setShowNotificationDropdown, origin }) => {
    const { dataApplication } = useGlobalState();
    const { socket } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(isOpen => !isOpen);
    };

    const handleDropdownItemClick = (e, type, item) => {
        e.stopPropagation();
        socket.emit('editNotification', { type: type, id: item.id })
        setDropdownOpen(false);
    };

    return (
        <div className="card pointer mb-3" onClick={(e) => notificationCardHandler(e, socket, item, navigate, location, setShowNotificationDropdown, origin)}>
            <div className="card-body py-2 pe-2 ps-3 horizontally-centered">
                <GenerateMessage item={item}></GenerateMessage>
                <Dropdown show={dropdownOpen} onToggle={handleDropdownToggle} onClick={(e) => e.stopPropagation()}>
                    <Dropdown.Toggle className="notificationButtons">
                        <FontAwesomeIcon className="dropdown-toggle me-1" size={'lg'} icon={faEllipsisVertical} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => handleDropdownItemClick(e, 'read_at', item)}>
                            <FontAwesomeIcon className="me-1 notificationDropdownIcon" icon={faEnvelopeOpen} />Marcar como lido
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => handleDropdownItemClick(e, 'hidden_at', item)}>
                            <FontAwesomeIcon className="me-1 notificationDropdownIcon" icon={faEyeSlash} />Ocultar notificação
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div >
    )
}

const notificationCardHandler = (e, socket, item, navigate, location, setShowNotificationDropdown, origin) => {
    if (origin === 1) {
        setShowNotificationDropdown(false)
    }
    socket.emit('editNotification', {
        type: 'read_at',
        id: item.id,
    });
    switch (item.type) {
        case 'friend_request_sent':
            navigate(`/participantes/${item.identifier}${location.search}`)
            break;
        case 'friend_request_receive':
            navigate(`/participantes/${item.identifier}${location.search}`)
            break;
        case 'event_reminder':
            navigate(`/agenda/${item.identifier}${location.search}`)
            break;
        case 'qrcode_contact_added':
            navigate(`/participantes/${item.identifier}${location.search}`)
            break;
        case 'qrcode_interest_point_added':
            navigate(`/pontos-de-interesse${location.search}`)
            break;
        default:
            break;
    }
}

const GenerateMessage = ({ item }) => {
    const { dataApplication } = useGlobalState();
    const imageLink = dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + '/'

    switch (item.type) {
        case 'friend_request_sent':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={item.json.image !== null && item.json.image !== '' ? imageLink + item.json.image : defaultAvatar} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small`}>
                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                if (field && item.json && item.json[field.inputID]) {
                                    if (item.json && item.json[field.inputID]) {
                                        if (index === 0) {
                                            return <b key={'generateMessageB' + item.id + index}>{item.json[field.inputID].value} </b>
                                        }
                                    }
                                }
                                return null; // Handle cases where the field is not found (optional)
                            })}
                            aceitou seu pedido de contato</p>
                    </div>
                </>
            )
        case 'friend_request_receive':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={item.json.image !== null && item.json.image !== '' ? imageLink + item.json.image : defaultAvatar} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small`}>
                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                if (field && item.json && item.json[field.inputID]) {
                                    if (item.json && item.json[field.inputID]) {
                                        if (index === 0) {
                                            return <b key={'generateMessageB' + item.id + index}>{item.json[field.inputID].value} </b>
                                        }
                                    }
                                }
                                return null; // Handle cases where the field is not found (optional)
                            })}
                            enviou um pedido de contato</p>
                    </div>
                </>
            )
        case 'event_reminder':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={calendarIcon} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small fw-bold`}>Lembrete</p>
                        <p className={`mb-0 small`}>O evento <b>"{item.json.title}"</b> começará em 15 minutos!</p>
                    </div>
                </>
            )
        case 'admin_notification':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={announcementIcon} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small fw-bold`}>Aviso</p>
                        <p className={`mb-0 small`}>{item.json.description}</p>
                    </div>
                </>
            )
        case 'qrcode_contact_added':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={item.json.image !== null && item.json.image !== '' ? imageLink + item.json.image : defaultAvatar} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small`}>
                            Você adicionou
                            {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                if (field && item.json && item.json[field.inputID]) {
                                    if (item.json && item.json[field.inputID]) {
                                        if (index === 0) {
                                            return <b key={'generateMessageB' + item.id + index}> {item.json[field.inputID].value} </b>
                                        }
                                    }
                                }
                                return null; // Handle cases where the field is not found (optional)
                            })}
                            pelo Código QR e ganhou 20 pts de bônus</p>
                        <p className="timeText opacity-75 mb-0 mt-1">{getMessageTime(item.created_at)}</p>
                    </div>
                </>
            )
        case 'qrcode_interest_point_added':
            return (
                <>
                    <div className="vertically-centered">
                        {item.read_at === null && <span className="readPoint"></span>}
                        <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + '/' + item.json.image} alt="messageImage" />
                    </div>
                    <div className="message">
                        <p className={`mb-0 small`}><b>{item.json.title}</b> coletado. Você ganhou 250 pts</p>
                    </div>
                </>
            )

        default:
            return (
                <><p><small className="opacity-75">Notificação inválida</small></p></>
            )
    }
}

function getMessageTime(providedDate) {
    const now = new Date();
    const date = new Date(providedDate);
    const diffInMilliseconds = now - date;

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    const diffInMonths = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 30));

    if (diffInMinutes < 1) {
        return 'menos de um minuto atrás';
    } else if (diffInMinutes === 1) {
        return '1 minuto atrás';
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} minutos atrás`;
    } else if (diffInHours === 1) {
        return '1 hora atrás';
    } else if (diffInHours < 24) {
        return `${diffInHours} horas atrás`;
    } else if (diffInDays === 1) {
        return '1 dia atrás';
    } else if (diffInDays < 7) {
        return `${diffInDays} dias atrás`;
    } else if (diffInWeeks === 1) {
        return '1 semana atrás';
    } else if (diffInWeeks < 4) {
        return `${diffInWeeks} semanas atrás`;
    } else if (diffInMonths === 1) {
        return '1 mês atrás';
    } else {
        return `${diffInMonths} meses atrás`;
    }
};

export default Notifications