import { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AnimatePresence, motion } from "framer-motion"
import { useSwipeable } from 'react-swipeable';
import axios from 'axios'
import useAuth from '../../hooks/useAuth';
import { FormComponent } from '../../components/Register.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
    faCamera
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../../hooks/useApp';
import tutorialImage01 from '../../assets/img/Reminders-pana.png'
import tutorialImage02 from '../../assets/img/QR Code-pana.png'
import tutorialImage03 from '../../assets/img/Social life-pana.png'
import tutorialImage04 from '../../assets/img/Checklist-pana.png'
import noProfile from '../../assets/img/default-avatar-profile-picture-male-icon.png'
import { useGlobalState } from '../../hooks/useInitialization';
import NotyfContext from "../../contexts/NotyfContext";
import { onSelectFile } from "../../components/Functions"
import PageLoader from '../../components/PageLoader.js';

export const TutorialType01 = () => {
    const { dataApplication } = useGlobalState()
    const { dispatch } = useAuth();
    const { socket } = useAppState();
    const [tutorial, setTutorial] = useState(1);
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);
    const [direction, setDirection] = useState('left');
    const [lastStep, setLastStep] = useState(1);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm()
        }
    };
    // function handleLanguage(lg) {
    //     setLanguage(lg)
    //     i18n.changeLanguage(lg)
    //     swipeLeft()
    // }

    function swipeLeft() {
        if (tutorial < 4) {
            const newTutorial = tutorial + 1;
            setDirection('right');
            setLastStep(newTutorial);
            setTutorial(newTutorial);
        }
    }

    function swipeRight() {
        if (tutorial > 1) {
            const newTutorial = tutorial - 1;
            setDirection('left');
            setLastStep(newTutorial);
            setTutorial(newTutorial);
        }
    }

    const handleNewStep = (step) => {
        if (step > lastStep) {
            setDirection('right');
        } else {
            setDirection('left');
        }
        setLastStep(step);
        setTutorial(step)
    }

    const registerUser = (values, actions) => {
        if (selectedFile === undefined) {
            actions.setSubmitting(false)
            notyf.open({
                type: "danger",
                message: 'Foto de perfil inválida',
                ripple: true,
                dismissible: true,
            });
            return
        }
        let json = {}
        if (values.authorization) {
            delete values.authorization
        }
        Object.entries(values).forEach(entry => {
            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
            if (field) {
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            }
        })
        const formData = new FormData();
        formData.append("file", selectedFile);
        axios
            .post(`https://api-dev.flexinterativa.com.br/api/upload/plataforma-eventos/${dataApplication.event_id}`, formData)
            .then((responseX) => {
                console.log(responseX)
                if (responseX.data.success === true) {
                    socket.emit('editUser', { create: true, json: JSON.stringify(json), image: responseX.data.message });
                } else {
                    notyf.open({
                        type: "danger",
                        message: 'Houve algum problema, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: error.message ? error.message : error,
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => swipeLeft(),
        onSwipedRight: () => swipeRight(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    useEffect(() => {
        if (socket) {
            socket.on('editUser', (result) => {
                result.json = JSON.parse(result.json)
                delete result.create
                dispatch({ type: "UPDATE_USER", payload: result });
                notyf.open({
                    type: "success",
                    message: 'Perfil criado com sucesso',
                    ripple: true,
                    dismissible: true,
                });
            });

            return () => {
                socket.off('editUser');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const variants = {
        enter: (direction) => ({
            x: direction === 'right' ? 100 : -100,
            opacity: 0,
        }),
        center: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.25 },
        },
        exit: (direction) => ({
            x: direction === 'right' ? -100 : 100,
            opacity: 0,
            transition: { duration: 0.25 },
        }),
    };

    return (
        <AnimatePresence mode="wait" initial={false} custom={direction}>
            {(() => {
                switch (tutorial) {
                    case 1:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial1A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">01</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Descubra o Que Está Acontecendo</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial1B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage01} alt="tutorialImage01"></img>
                                    <p>Navegue pela página de eventos para descobrir todos os detalhes sobre o que está acontecendo e garantir que você não perca nada do que o evento tem a oferecer</p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(2)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 2:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial2A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">02</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Conecte-se e Ganhe Recompensas</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial2B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage02} alt="tutorialImage02"></img>
                                    <p>
                                        Ao escanear os códigos QR de participantes ou "Pontos de Interesse" você pode rapidamente acumular pontos e concorrer a prêmios!
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(3)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 3:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial3A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">03</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Amplie Seu Networking</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial3B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage03} alt="tutorialImage03"></img>
                                    <p>
                                        Utilize nossa ferramenta de networking para expandir seu círculo profissional. Você pode iniciar conversas e interagir diretamente pelo app.
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(4)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 4:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial4A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">04</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Finalize Seu Registro</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial4B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage04} alt="tutorialImage04"></img>
                                    <p>
                                        Prossiga para escolher uma foto e adicionar suas informações de perfil
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(5)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    default:
                        return (
                            <motion.div className="tutorialContainerProfile" key={'tutorial5A'} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className='profileHeader mb-3'>
                                    <div className="profileTitle tutorial-body-center">
                                        <h5><FontAwesomeIcon size={'lg'} icon={faUser} className="me-2" />Crie seu perfil</h5>
                                        <p className="small">Preencha suas informações para aproveitar ao máximo o evento</p>
                                    </div>
                                    <label htmlFor="my-file">
                                        <img src={!preview ? noProfile : preview} alt="sponsor logo"></img>
                                        <div className="cameraIcon">
                                            <FontAwesomeIcon size={'lg'} icon={faCamera} />
                                        </div>
                                    </label>
                                    <input
                                        className="d-none"
                                        type="file"
                                        id="my-file"
                                        onChange={(e) => {
                                            onSelectFile(e, setSelectedFile, notyf)
                                        }}
                                    />
                                </div>
                                <div className="profileBody tutorial-body-center">
                                    <FormComponent formikRef={formikRef} registerUser={registerUser}></FormComponent>
                                    <div className="mt-4">
                                        <Button variant="primary" onClick={submitFormExternally}>
                                            {formikRef.current && formikRef.current.isSubmitting ? <PageLoader color={"#fff"} width="25" padding={0}></PageLoader> : 'Finalizar'}
                                        </Button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                }
            })()}
        </AnimatePresence>
    )
}

export const TutorialType02 = () => {
    const { dataApplication } = useGlobalState()
    const { dispatch } = useAuth();
    const { socket } = useAppState();
    const [tutorial, setTutorial] = useState(1);
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);
    const [direction, setDirection] = useState('left');
    const [lastStep, setLastStep] = useState(1);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm()
        }
    };
    // function handleLanguage(lg) {
    //     setLanguage(lg)
    //     i18n.changeLanguage(lg)
    //     swipeLeft()
    // }

    function swipeLeft() {
        if (tutorial < 4) {
            const newTutorial = tutorial + 1;
            setDirection('right');
            setLastStep(newTutorial);
            setTutorial(newTutorial);
        }
    }

    function swipeRight() {
        if (tutorial > 1) {
            const newTutorial = tutorial - 1;
            setDirection('left');
            setLastStep(newTutorial);
            setTutorial(newTutorial);
        }
    }

    const handleNewStep = (step) => {
        if (step > lastStep) {
            setDirection('right');
        } else {
            setDirection('left');
        }
        setLastStep(step);
        setTutorial(step)
    }

    const registerUser = (values, actions) => {
        if (selectedFile === undefined) {
            actions.setSubmitting(false)
            notyf.open({
                type: "danger",
                message: 'Foto de perfil inválida',
                ripple: true,
                dismissible: true,
            });
            return
        }
        let json = {}
        if (values.authorization) {
            delete values.authorization
        }
        Object.entries(values).forEach(entry => {
            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
            if (field) {
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            }
        })
        const formData = new FormData();
        formData.append("file", selectedFile);
        axios
            .post(`https://api-dev.flexinterativa.com.br/api/upload/plataforma-eventos/${dataApplication.event_id}`, formData)
            .then((responseX) => {
                console.log(responseX)
                if (responseX.data.success === true) {
                    socket.emit('editUser', { create: true, json: JSON.stringify(json), image: responseX.data.message });
                } else {
                    notyf.open({
                        type: "danger",
                        message: 'Houve algum problema, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: error.message ? error.message : error,
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => swipeLeft(),
        onSwipedRight: () => swipeRight(),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    useEffect(() => {
        if (socket) {
            socket.on('editUser', (result) => {
                result.json = JSON.parse(result.json)
                delete result.create
                dispatch({ type: "UPDATE_USER", payload: result });
                notyf.open({
                    type: "success",
                    message: 'Perfil criado com sucesso',
                    ripple: true,
                    dismissible: true,
                });
            });

            return () => {
                socket.off('editUser');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const variants = {
        enter: (direction) => ({
            x: direction === 'right' ? 100 : -100,
            opacity: 0,
        }),
        center: {
            x: 0,
            opacity: 1,
            transition: { duration: 0.25 },
        },
        exit: (direction) => ({
            x: direction === 'right' ? -100 : 100,
            opacity: 0,
            transition: { duration: 0.25 },
        }),
    };

    return (
        <AnimatePresence mode="wait" initial={false} custom={direction}>
            {(() => {
                switch (tutorial) {
                    case 1:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial1A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">01</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Descubra o Que Está Acontecendo</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial1B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage01} alt="tutorialImage01"></img>
                                    <p>Navegue pela página de eventos para descobrir todos os detalhes sobre o que está acontecendo e garantir que você não perca nada do que o evento tem a oferecer</p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(2)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 2:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial2A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">02</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Conecte-se e Ganhe Recompensas</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial2B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage02} alt="tutorialImage02"></img>
                                    <p>
                                        Ao escanear os códigos QR de participantes ou "Pontos de Interesse" você pode rapidamente acumular pontos e concorrer a prêmios!
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(3)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 3:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial3A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">03</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Amplie Seu Networking</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial3B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage03} alt="tutorialImage03"></img>
                                    <p>
                                        Utilize nossa ferramenta de networking para expandir seu círculo profissional. Você pode iniciar conversas e interagir diretamente pelo app.
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <p className="tutorialSkip primaryColorT pointer" onClick={(() => handleNewStep(4))}>Pular</p>
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(4)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    case 4:
                        return (
                            <motion.div className="tutorialContainer" key={'tutorial4A'} {...handlers} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className="flex-grow-1 mt-3">
                                    <div className="d-flex align-items-center">
                                        <div className="tutorialTitleRound">04</div>
                                        <h6 className="mb-0 ms-3 fw-bold">Finalize Seu Registro</h6>
                                    </div>
                                </div>
                                <div className="flex-grow-1 text-center tutorial-body-center" key={'tutorial4B'}>
                                    <img className="my-3 storysetImages" src={tutorialImage04} alt="tutorialImage04"></img>
                                    <p>
                                        Prossiga para escolher uma foto e adicionar suas informações de perfil
                                    </p>
                                    <div className="mt-5 mb-3">
                                        <div className='tutorialSlideController mb-5'>
                                            <div className={tutorial === 1 ? "" : "opacity-25"} onClick={(() => handleNewStep(1))}></div>
                                            <div className={tutorial === 2 ? "" : "opacity-25"} onClick={(() => handleNewStep(2))}></div>
                                            <div className={tutorial === 3 ? "" : "opacity-25"} onClick={(() => handleNewStep(3))}></div>
                                            <div className={tutorial === 4 ? "" : "opacity-25"} onClick={(() => handleNewStep(4))}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button" onClick={() => handleNewStep(5)}>Continuar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    default:
                        return (
                            <motion.div className="tutorialContainerProfile" key={'tutorial5A'} variants={variants} custom={direction} initial="enter" animate="center" exit="exit">
                                <div className='profileHeader mb-3'>
                                    <div className="profileTitle tutorial-body-center">
                                        <h5><FontAwesomeIcon size={'lg'} icon={faUser} className="me-2" />Crie seu perfil</h5>
                                        <p className="small">Preencha suas informações para aproveitar ao máximo o evento</p>
                                    </div>
                                    <label htmlFor="my-file">
                                        <img src={!preview ? noProfile : preview} alt="sponsor logo"></img>
                                        <div className="cameraIcon">
                                            <FontAwesomeIcon size={'lg'} icon={faCamera} />
                                        </div>
                                    </label>
                                    <input
                                        className="d-none"
                                        type="file"
                                        id="my-file"
                                        onChange={(e) => {
                                            onSelectFile(e, setSelectedFile, notyf)
                                        }}
                                    />
                                </div>
                                <div className="profileBody tutorial-body-center">
                                    <FormComponent formikRef={formikRef} registerUser={registerUser}></FormComponent>
                                    <div className="mt-4">
                                        <Button variant="primary" onClick={submitFormExternally}>
                                            {formikRef.current && formikRef.current.isSubmitting ? <PageLoader color={"#fff"} width="25" padding={0}></PageLoader> : 'Finalizar'}
                                        </Button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                }
            })()}
        </AnimatePresence>
    )
}

