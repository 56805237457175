import { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom";
import PageLoader from '../../components/PageLoader'
import { useAppState } from '../../hooks/useApp'
import { useGlobalState } from '../../hooks/useInitialization'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Grid, Pagination } from 'swiper/modules';
import { EventCard, handleInterest } from './Schedule.js'
import { SpeakerCard } from './Speakers.js'
import { SponsorCardHome } from './Sponsors.js'
import { ParticipantsCard, updateCurrentData } from './Networking'
import { ExhibitorCard } from './Exhibitors.js'
import { NetworkingConfirmationModal } from '../../components/Modals.js';
import useAuth from '../../hooks/useAuth.js';
import { TextWithLineBreaks } from "../../components/Functions.js"
import SwiperCore from 'swiper';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const Home = () => {
    const { dataApplication } = useGlobalState()
    const { appState, updatePageDataNetworking, setUpdatePageDataNetworking, socket } = useAppState()
    const [pageData, setPageData] = useState();

    useEffect(() => {
        if (socket) {
            if (Object.keys(updatePageDataNetworking.data).length > 0) {
                updateCurrentData(
                    updatePageDataNetworking.data.newStatus,
                    parseInt(updatePageDataNetworking.data.id),
                    pageData,
                    setPageData,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    updatePageDataNetworking.data.origin,
                    updatePageDataNetworking.data.other_user_relationship_status
                );
                setUpdatePageDataNetworking(prevState => ({
                    ...prevState,
                    data: {}
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePageDataNetworking.count])

    useEffect(() => {
        if (socket) {
            socket.on('pageDataHome', (response) => {
                // dispatch({ type: 'recommendations', payload: response });
                setPageData(response);
            });
            socket.emit('pageDataHome', { page: 'home' });
            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataHome');
            };
        }
    }, [socket, appState.CONNECTED]);

    return (
        <motion.div className="homeContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header>
                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    loop={true}
                    className="mySwiper"
                >
                    {dataApplication.customizacao.banner && dataApplication.customizacao.banner.map((img, index) => (
                        <SwiperSlide key={'banner' + index}>
                            <div className="banner">
                                <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + img} alt={`Banner home ${index}`}></img>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {dataApplication.customizacao.home.status && <div className="container pt-3">
                    <div className="eventContainer">
                        <div>
                            <h1 className="mb-5 mt-3 text-center fw-bold">{dataApplication.customizacao.home.title}</h1>
                            <p><TextWithLineBreaks text={dataApplication.customizacao.home.content}></TextWithLineBreaks></p>
                        </div>
                    </div>
                </div>}
            </header>
            {!pageData ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <Reccomendations pageData={pageData} setPageData={setPageData} ></Reccomendations>
            )}
        </motion.div>
    )
}

const Reccomendations = ({ pageData, setPageData }) => {
    const { dataApplication } = useGlobalState()
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth()
    const [slidePerViewNetworking, setSlidePerViewNetworking] = useState(true);

    // Function to check if the screen size is small
    const updateSlideNetworking = () => {
        const screenWidth = window.innerWidth;
        let slides = 1
        if (screenWidth > 576) {
            slides = 2
        }
        if (screenWidth > 1024) {
            slides = 3
        }
        // Assuming 'sm' breakpoint at 576px, adjust as needed
        setSlidePerViewNetworking(slides);
    };

    useEffect(() => {
        updateSlideNetworking();
        window.addEventListener('resize', updateSlideNetworking);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateSlideNetworking);
        };
    }, []);

    if (pageData) {
        return (
            <div className="container pt-3">
                {/* Schedule */}
                {dataApplication.customizacao.sections.some(item => item.href === '/agenda') && <div className="eventContainer">
                    <p className="fw-bold mb-2 d-flex justify-content-between"><span>Próximos eventos</span><span className="small primaryColorT pointer" onClick={(() => navigate(`/agenda${location.search}`))}>Ver todos</span></p>
                    <Swiper
                        slidesPerView={'auto'}
                        centeredSlides={false}
                        spaceBetween={15}
                        className="mySwiper"
                    >
                        {(Array.isArray(pageData.schedule)) && pageData.schedule.length > 0 ? (
                            <>
                                {pageData.schedule.map((item, index) => (
                                    <SwiperSlide key={'EventCardHome' + index}>
                                        <Col>
                                            <EventCard item={item} pageData={pageData} setPageData={setPageData} handleInterest={handleInterest} tabKey={undefined} layout={1} origin={'home'} />
                                        </Col>
                                    </SwiperSlide>
                                ))}
                            </>
                        ) : (
                            <Row className="mb-3">
                                <Col className="text-center my-5" sm={12}>
                                    <p className="opacity-75">Nenhum evento encontrado</p>
                                </Col>
                            </Row>
                        )}
                    </Swiper>
                </div>}

                {/* Exhibitors */}
                {dataApplication.customizacao.sections.some(item => item.href === '/expositores') && <div className="exhibitorContainer">
                    <p className="fw-bold mb-2 d-flex justify-content-between"><span>Expositores</span><span className="small pointer primaryColorT" onClick={(() => navigate(`/expositores${location.search}`))}>Ver todos</span></p>
                    <Swiper
                        slidesPerView={'auto'}
                        centeredSlides={false}
                        spaceBetween={15}
                        className="mySwiper"
                    >
                        {(Array.isArray(pageData.exhibitors)) && pageData.exhibitors.length > 0 ? (
                            <>
                                {pageData.exhibitors.map((item, index) => (
                                    <SwiperSlide key={'SpeakerCard' + index} >
                                        <ExhibitorCard item={item} pageData={pageData} setPageData={setPageData} origin={'home'}></ExhibitorCard>
                                    </SwiperSlide>
                                ))}
                            </>
                        ) : (
                            <Row className="mb-3">
                                <Col className="text-center my-5" sm={12}>
                                    <p className="opacity-75">Nenhum expositor encontrado</p>
                                </Col>
                            </Row>
                        )}
                    </Swiper>
                </div>}

                {/* Speakers */}
                {dataApplication.customizacao.sections.some(item => item.href === '/palestrantes') && <div className="speakerContainer">
                    <p className="fw-bold mb-2 d-flex justify-content-between"><span>Palestrantes</span><span className="small pointer primaryColorT" onClick={(() => navigate(`/palestrantes${location.search}`))}>Ver todos</span></p>
                    <Swiper
                        slidesPerView={'auto'}
                        centeredSlides={false}
                        spaceBetween={15}
                        className="mySwiper"
                    >
                        {(Array.isArray(pageData.speakers)) && pageData.speakers.length > 0 ? (
                            <>
                                {pageData.speakers.map((item, index) => (
                                    <SwiperSlide key={'SpeakerCard' + index} >
                                        <SpeakerCard item={item} pageData={pageData} setPageData={setPageData} origin={'home'} />
                                    </SwiperSlide>
                                ))}
                            </>
                        ) : (
                            <Row className="mb-3">
                                <Col className="text-center my-5" sm={12}>
                                    <p className="opacity-75">Nenhum palestrante encontrado</p>
                                </Col>
                            </Row>
                        )}
                    </Swiper>
                </div>}

                <NetworkingConfirmationModal pageData={pageData} setPageData={setPageData} pageDataItem={undefined} setPageDataItem={undefined} setTotalInvites={undefined}></NetworkingConfirmationModal>
                {(dataApplication.customizacao.sections.some(item => item.href === '/participantes') && user.type === 0) ?
                    <div className="networkingContainer mb-3">
                        <p className="fw-bold mb-2 d-flex justify-content-between"><span>
                            {user.type === 0 ? 'Sugestão de contatos' : 'Participantes'}
                        </span><span className="small pointer primaryColorT" onClick={(() => navigate(`/contatos${location.search}`))}>Ver todos</span></p>
                        <Swiper
                            slidesPerView={slidePerViewNetworking}
                            grid={{ rows: 3 }}
                            spaceBetween={15}
                            modules={[Grid, Pagination]}
                            pagination={{
                                clickable: true,
                            }}
                            className="mySwiper"
                        >
                            {(Array.isArray(pageData.networking)) && pageData.networking.length > 0 ? (
                                <>
                                    {pageData.networking.map((item, index) => (
                                        <SwiperSlide key={'networkingCard' + index} className="networking-card">
                                            <ParticipantsCard item={item} pageData={pageData} setPageData={setPageData} setTotalInvites={undefined} origin={'home'} />
                                        </SwiperSlide>
                                    ))}
                                </>
                            ) : (
                                <Row className="mb-3">
                                    <Col className="text-center my-5" sm={12}>
                                        <p className="opacity-75">Nenhum participante encontrado</p>
                                    </Col>
                                </Row>
                            )}
                        </Swiper>
                    </div>
                    : ""}

                {/* Sponsors */}
                {dataApplication.customizacao.sections.some(item => item.href === '/patrocinadores') && <div className="sponsorContainer">
                    <p className="fw-bold mb-2 d-flex justify-content-between"><span>Patrocinadores / Expositores</span><span className="small primaryColorT pointer" onClick={(() => navigate(`/patrocinadores${location.search}`))}>Ver todos</span></p>
                    <Swiper
                        slidesPerView={'auto'}
                        centeredSlides={false}
                        spaceBetween={15}
                        className="mySwiper"
                    >
                        {(Array.isArray(pageData.sponsors)) && pageData.sponsors.length > 0 ? (
                            <>
                                {pageData.sponsors.map((item, index) => (
                                    <SwiperSlide key={'SponsorCardHome' + index} >
                                        <SponsorCardHome item={item} />
                                    </SwiperSlide>
                                ))}
                            </>
                        ) : (
                            <Row className="mb-3">
                                <Col className="text-center my-5" sm={12}>
                                    <p className="opacity-75">Nenhum patrocinador encontrado</p>
                                </Col>
                            </Row>
                        )}
                    </Swiper>
                </div>}
            </div>
        )
    }
}

export default Home