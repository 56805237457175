import React, { useState } from "react";

import useSettingsState from "../hooks/useSettingsState";

const initialState = {
  isOpen: true,
  position: 'left',
};

const SidebarContext = React.createContext(initialState);

function SidebarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(window.innerWidth <= 769 ? false : true);
  const [position, setPosition] = useSettingsState("sidebarPosition", 'left')

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setIsOpen,
        position,
        setPosition,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
