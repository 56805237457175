import React from 'react';
import useAuth from "../hooks/useAuth";
import { useAppState } from '../hooks/useApp.js';
import { Navigate, useLocation } from "react-router-dom";

function AuthGuard({ children }) {
  const location = useLocation();
  const { appState } = useAppState();
  const { isAuthenticated, isInitialized } = useAuth();

  // Helper function to check if the current route exists in appState.sections.sections
  const isRouteAllowed = (path) => {
    // Extract only the base path (for routes with ids, like '/agenda/:id')
    const basePath = path.split("/")[1] ? `/${path.split("/")[1]}` : "/";

    // Check if the base path matches any href in appState.sections.sections
    return appState.sections.sections.some(section => section.href === basePath);
  };

  // Handle not initialized or missing sections
  if (!isInitialized) {
    return <React.Fragment></React.Fragment>;
  }

  // Handle unauthenticated state
  if (!isAuthenticated) {
    return <Navigate to={`/login${location.search}`} />;
  }

  if (!appState.sections) {
    return <React.Fragment></React.Fragment>;
  }

  // Check if the current route exists in appState.sections.sections
  if (!isRouteAllowed(location.pathname)) {
    return <Navigate to={`/${location.search}`}  />;
  }


  // Render the children if all checks pass
  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;