import { useState, useContext, useRef, useEffect } from 'react'
import { Button, Row, Form, Col } from 'react-bootstrap'
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCamera } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../hooks/useInitialization.js';
import { useAppState } from '../../hooks/useApp'
import NotyfContext from "../../contexts/NotyfContext";
import { FormComponent } from '../../components/Register'
import { onSelectFile, handleNotification } from "../../components/Functions"
import defaultAvatar from '../../assets/img/default-avatar-profile-picture-male-icon.png'
import PageLoader from '../../components/PageLoader.js';
import useAuth from '../../hooks/useAuth.js';

const Perfil = () => {
    const { user, dispatch } = useAuth()
    const { dataApplication } = useGlobalState();
    const { socket, setLoading } = useAppState()
    const [notifications, setNotifications] = useState(user.push_subscription === null ? false : true)
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [preview, setPreview] = useState(undefined)
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef(null);
    const imageLink = dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/"

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm()
            formikRef.current.setSubmitting(true);
        }
    };

    const registerUser = (values, actions) => {
        let json = {}
        if (values.authorization) {
            delete values.authorization
        }
        Object.entries(values).forEach(entry => {
            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
            if (field) {
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            }
        })
        const formData = new FormData();
        formData.append("file", selectedFile);

        if (selectedFile) {
            axios
                .post(`https://api-dev.flexinterativa.com.br/api/upload/plataforma-eventos/${dataApplication.event_id}`, formData)
                .then((responseX) => {
                    if (responseX.data.success === true) {
                        socket.emit('editUser', { create: false, json: JSON.stringify(json), image: responseX.data.message });
                    } else {
                        notyf.open({
                            type: "danger",
                            message: 'Houve algum problema, tente novamente',
                            ripple: true,
                            dismissible: true,
                        });
                    }
                    actions.setSubmitting(false)
                })
                .catch((error) => {
                    notyf.open({
                        type: "danger",
                        message: 'Imagem inválida',
                        ripple: true,
                        dismissible: true,
                    });
                });
        } else {
            socket.emit('editUser', { create: false, json: JSON.stringify(json), image: user.image });
            actions.setSubmitting(false)
        }
    }

    useEffect(() => {
        if (socket && user.status === 1) {
            socket.on('editUser', (result) => {
                result.json = JSON.parse(result.json)
                delete result.create
                dispatch({ type: "UPDATE_USER", payload: result });
                notyf.open({
                    type: "success",
                    message: 'Perfil atualizado com sucesso',
                    ripple: true,
                    dismissible: true,
                });
            });

            return () => {
                socket.off('editUser');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    return (
        <motion.div className="container networkingContainer perfilContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Meu perfil</h5></li>
                    </ol>
                </nav>
            </header>
            <div className='networkingItemHeader mb-3'>
                <label htmlFor="my-file">
                    <img src={!preview ? (user.image !== null && user.image !== '') ? imageLink + user.image : defaultAvatar : preview} alt="sponsor logo"></img>
                    <div className="cameraIcon">
                        <FontAwesomeIcon size={'lg'} icon={faCamera} />
                    </div>
                </label>
                <input
                    className="d-none"
                    type="file"
                    id="my-file"
                    onChange={(e) => {
                        onSelectFile(e, setSelectedFile, notyf)
                    }}
                />
            </div>
            <Row className="networkingItemBody">
                {user.type === 0 && <FormComponent formikRef={formikRef} registerUser={registerUser} editValues={user.json}></FormComponent>}
                <Form.Group className="mb-3 text-start col-sm-12">
                    <Form.Label>Configurações</Form.Label>
                    <Form.Check
                        onChange={() => handleNotification(socket, 'perfil', setNotifications, setLoading)}
                        checked={notifications}
                        type="checkbox"
                        className="small"
                        label={`Permitir notificações em tempo real sobre atualizações e eventos importantes.`}>
                    </Form.Check>
                </Form.Group>
            </Row>
            {user.type === 0 && <div className="my-3 text-center">
                <Button variant="primary" onClick={submitFormExternally}>
                    {formikRef.current && formikRef.current.isSubmitting ? <PageLoader color={"#fff"} width="25" padding={0}></PageLoader> : 'Salvar'}
                </Button>
            </div>}
        </motion.div>
    )
}

export default Perfil