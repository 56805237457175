import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppState } from "../hooks/useApp";

export default function ScrollToTop() {
  const { appState } = useAppState();
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableElement = document.querySelector('.main'); // Change this to your scrollable container's selector

    if (scrollableElement) {
      setTimeout(() => {
        scrollableElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 100); // Adjust the delay as needed
    } else {
      console.error('Scrollable element not found');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.updatePageDataItem]);

  return null;
}