import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import {
    Col,
    Form,
    Row,
    Container,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup";
import NotyfContext from "../contexts/NotyfContext.js";
import { useGlobalState } from '../hooks/useInitialization.js';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { redirectURL, SocialMidiaIcons } from '../components/Functions'
import useAuth from "../hooks/useAuth";
import PageLoader from '../components/PageLoader.js';

export const Login = () => {
    const { dataApplication } = useGlobalState();
    const notyf = useContext(NotyfContext);
    const { signIn } = useAuth();
    const [requestCode, setRequestCode] = useState(false)

    function handleCodeRequest(email, setFieldError, setSubmitting) {
        setSubmitting(true)
        axios.post("/api/requestLoginCode", {
            event_id: dataApplication.event_id,
            email: email
        }).then(function (response) {
            setSubmitting(false)
            setRequestCode(true)
            setFieldError('email', undefined)
            notyf.open({
                type: "success",
                message: 'Código de acesso enviado',
                ripple: true,
                dismissible: true,
            });
        }).catch(function (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.message === 1) {
                setFieldError('email', 'Email não cadastrado para este evento')
            } else {
                notyf.open({
                    type: "danger",
                    message: 'Houve um erro de conexão com o servidor',
                    ripple: true,
                    dismissible: true,
                });
            }
            setSubmitting(false)
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 13 || event.key === "Enter") { // Check if the "End" key was pressed
                event.preventDefault();
                if (!requestCode) {
                    document.getElementById('submitButton').click();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div className="loginContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }} exit={{ opacity: 0 }}>
            {dataApplication.customizacao.login.imageSide.value > 0 && <div className="imageSide"></div>}
            <Container className="h-100 textSide">
                <div className={`box ${dataApplication.customizacao.layout.footer.status ? 'boxWithFooter' : ''}`}>
                    <div className="mt-auto pt-5 text-center">
                        <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo mb-4"></img>
                        <div className="py-3">
                            <h2 className="mb-3">Seja bem vindo(a)</h2>
                            <p className="text-opacity-75 mb-0">{dataApplication.title}</p>
                        </div>
                        <Formik
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .required("Campo obrigatório")
                                    .max(255, `Máximo de 255 caracteres`),
                                access_code: Yup.string()
                                    .required("Campo obrigatório")
                                    .max(50, `Máximo de 50 caracteres`),
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    await signIn(values.email, values.access_code);
                                } catch (error) {
                                    var type = "danger";
                                    var ripple = true;
                                    var dismissible = true;
                                    notyf.open({
                                        type,
                                        message: error,
                                        ripple,
                                        dismissible,
                                    });
                                    setSubmitting(false)
                                }
                            }}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize
                            initialValues={{
                                email: '',
                                access_code: ''
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                                dirty,
                                isSubmitting,
                                setSubmitting,
                                setFieldValue,
                                setFieldError
                            }) => (
                                <Form noValidate onSubmit={handleSubmit} className="text-start">
                                    <Row className="my-4">
                                        <Form.Group as={Col} sm={12} className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete="on"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                isValid={touched.email && !errors.email}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <AnimatePresence mode="sync" initial={false}>
                                            {requestCode && (
                                                <motion.div
                                                    key="accessCodeGranted"
                                                    initial={{ height: 0, opacity: 0 }}
                                                    animate={{ height: 'auto', opacity: 1 }}
                                                    exit={{ height: 0, opacity: 0 }}
                                                    transition={{ duration: 0.5 }}
                                                    style={{ overflow: 'hidden' }}
                                                >
                                                    <Form.Group as={Col} sm={12} className="mb-3">
                                                        <Form.Label>
                                                            Código de acesso
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Solicitar novo código</Tooltip>}>
                                                                <FontAwesomeIcon
                                                                    onClick={() => handleCodeRequest(values.email, setFieldError, setSubmitting)}
                                                                    className="primaryColorT ms-2 pointer"
                                                                    icon={faRefresh}
                                                                    size="lg"
                                                                />
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="access_code"
                                                            value={values.access_code}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.access_code}
                                                            isValid={touched.access_code && !errors.access_code}
                                                        />
                                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.access_code}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </motion.div>
                                            )}
                                            <motion.div
                                                key="accessCodeGranted2"
                                                className="text-center py-3"
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: 'auto', opacity: 1 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                                style={{ overflow: 'hidden' }}
                                            >
                                                <Button
                                                    variant="primary"
                                                    id="submitButton"
                                                    type={requestCode ? 'submit' : 'button'}
                                                    disabled={isSubmitting}
                                                    onClick={() => requestCode ? '' : handleCodeRequest(values.email, setFieldError, setSubmitting)}
                                                >
                                                    {!isSubmitting ? requestCode ? 'Entrar' : 'Solicitar código de acesso' : <PageLoader color="#fff" width="25" padding={0} />}
                                                </Button>
                                            </motion.div>
                                            <div className="text-center mt-4">
                                                <p className="small mb-0 pointer" onClick={() => redirectURL(true, 'https://flexinterativa.com.br/politica-privacidade')}>
                                                    <FontAwesomeIcon className="me-2 pointer" icon={faExclamationCircle} size="lg" />Aceito os Termos de Uso e Privacidade
                                                </p>
                                            </div>
                                        </AnimatePresence>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {dataApplication.customizacao.layout.footer.type === 'image' ?
                        (
                            <div className="loginFooter">
                                <img key={'footerImg'} src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.layout.footer.url} width={dataApplication.customizacao.layout.footer.width} alt={'showFooterImg'}></img>
                            </div>
                        ) : (
                            <div className="baseColorText loginFooter">
                                {dataApplication.customizacao.layout.footer.socialMedia.length > 0 && dataApplication.customizacao.layout.footer.socialMedia.map((element, index) => (
                                    <SocialMidiaIcons key={'icons'} element={element} index={index} />
                                ))}
                            </div>
                        )}
                </div>
            </Container>
        </motion.div>
    )
}

export const LoginWithPassword = () => {
    const { dataApplication } = useGlobalState();
    const notyf = useContext(NotyfContext);
    const { signIn } = useAuth();

    function handleCodeRequest(email, setFieldError, setSubmitting) {
        setSubmitting(true)
        axios.post("/api/requestLoginCode", {
            event_id: dataApplication.event_id,
            usePassword: true,
            email: email
        }).then(function (response) {
            notyf.open({
                type: "success",
                message: 'Código de acesso enviado',
                ripple: true,
                dismissible: true,
            });
            setSubmitting(true)
        }).catch(function (error) {
            console.log(error)
            if (error.response && error.response.data && error.response.data.message === 1) {
                setFieldError('email', 'Email não cadastrado para este evento')
            } else {
                notyf.open({
                    type: "danger",
                    message: 'Houve um erro de conexão com o servidor',
                    ripple: true,
                    dismissible: true,
                });
            }
            setSubmitting(false)
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 13 || event.key === "Enter") { // Check if the "End" key was pressed
                event.preventDefault();
                document.getElementById('submitButton').click();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div className="loginContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }} exit={{ opacity: 0 }}>
            {dataApplication.customizacao.login.imageSide.value > 0 && <div className="imageSide"></div>}
            <Container className="h-100 textSide">
                <div className={`box ${dataApplication.customizacao.layout.footer.status ? 'boxWithFooter' : ''}`}>
                    <div className="mt-auto pt-5 text-center">
                        <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="logo mb-4"></img>
                        <div className="py-3">
                            <h2 className="mb-3">Seja bem vindo(a)</h2>
                            <p className="text-opacity-75 mb-0">{dataApplication.title}</p>
                        </div>
                        <Formik
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .required("Campo obrigatório")
                                    .max(255, `Máximo de 255 caracteres`),
                                access_code: Yup.string()
                                    .required("Campo obrigatório")
                                    .max(50, `Máximo de 50 caracteres`),
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    await signIn(values.email, values.access_code);
                                } catch (error) {
                                    var type = "danger";
                                    var ripple = true;
                                    var dismissible = true;
                                    notyf.open({
                                        type,
                                        message: error,
                                        ripple,
                                        dismissible,
                                    });
                                    setSubmitting(false)
                                }
                            }}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize
                            initialValues={{
                                email: '',
                                access_code: ''
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                                dirty,
                                isSubmitting,
                                setSubmitting,
                                setFieldValue,
                                setFieldError
                            }) => (
                                <Form noValidate onSubmit={handleSubmit} className="text-start">
                                    <Row className="my-4">
                                        <Form.Group as={Col} sm={12} className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete="on"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                isValid={touched.email && !errors.email}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div>
                                            <Form.Group as={Col} sm={12} className="mb-3">
                                                <Form.Label>
                                                    Código de acesso
                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Solicitar novo código</Tooltip>}>
                                                        <FontAwesomeIcon
                                                            onClick={() => handleCodeRequest(values.email, setFieldError, setSubmitting)}
                                                            className="primaryColorT ms-2 pointer"
                                                            icon={faRefresh}
                                                            size="lg"
                                                        />
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="access_code"
                                                    value={values.access_code}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.access_code}
                                                    isValid={touched.access_code && !errors.access_code}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.access_code}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="text-center py-3">
                                            <Button
                                                variant="primary"
                                                id="submitButton"
                                                type={'submit'}
                                                disabled={isSubmitting}
                                            >
                                                {!isSubmitting ? 'Entrar' : <PageLoader color="#fff" width="25" padding={0} />}
                                            </Button>
                                        </div>
                                        <div className="text-center mt-4">
                                            <p className="small mb-0 pointer" onClick={() => redirectURL(true, 'https://flexinterativa.com.br/politica-privacidade')}>
                                                <FontAwesomeIcon className="me-2 pointer" icon={faExclamationCircle} size="lg" />Aceito os Termos de Uso e Privacidade
                                            </p>
                                        </div>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {dataApplication.customizacao.layout.footer.type === 'image' ?
                        (
                            <div className="loginFooter">
                                <img key={'footerImg'} src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + dataApplication.customizacao.layout.footer.url} width={dataApplication.customizacao.layout.footer.width} alt={'showFooterImg'}></img>
                            </div>
                        ) : (
                            <div className="baseColorText loginFooter">
                                {dataApplication.customizacao.layout.footer.socialMedia.length > 0 && dataApplication.customizacao.layout.footer.socialMedia.map((element, index) => (
                                    <SocialMidiaIcons key={'icons'} element={element} index={index} />
                                ))}
                            </div>
                        )}
                </div>
            </Container>
        </motion.div>
    )
}