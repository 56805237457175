import React from 'react';
import { Row, Col, Button } from 'react-bootstrap'
import { motion } from "framer-motion"
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useGlobalState } from '../../hooks/useInitialization.js';

const Links = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { props } = location.state || {};

    return (
        <motion.div className="container" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            {props ? (
                <header className="pageHeader">
                    <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                        <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                    </Button>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb ms-2">
                            <li className="breadcrumb-item"><h5>{props.title}</h5></li>
                        </ol>
                    </nav>
                </header>
            ) : (
                <header className="pageHeader">
                    <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                        <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                    </Button>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb ms-2">
                            <li className="breadcrumb-item"><h5>Links</h5></li>
                        </ol>
                    </nav>
                </header>
            )}
            <VariableComponent props={props}></VariableComponent>
        </motion.div>
    )


};

export const VariableComponent = ({ props }) => {
    const { dataApplication } = useGlobalState()
    
    if (!props.content) {
        return (
            <Row className="mb-3">
                <Col sm={12}>
                    <div className="text-center mt-4" sm={12}>
                        <p className="opacity-75">Nenhumm conteúdo encontrado</p>
                    </div>
                </Col>
            </Row>
        )
    }

    switch (props.content.type) {
        default:
            return (
                <div className="container-fluid px-0 py-3 text-center">
                    <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + props.content.url} alt="Landing Page" className="img-fluid" />
                </div>
            )

    }
}

export default Links;