import React from 'react';
import { Modal } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner'

export function ConfirmDialog({ title, body, show, handleClose, handleConfirm }) {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <button className="btn primaryColorT" onClick={() => handleClose()}>
                    Voltar
                </button>
                <button className="btn btn-primary" onClick={() => handleConfirm()}>
                    Continuar
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export const PageLoader = ({ color, width }) => {
    return (
        <>
            <div className="loaderScreen py-2">
                <RotatingLines
                    strokeColor={color}
                    strokeWidth="5"
                    animationDuration="0.5"
                    width={width}
                    visible={true}
                />
            </div>
        </>
    )
}