import React from 'react';
import useAuth from "../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

function GuestGuard({ children }) {
    const location = useLocation();
    const { isAuthenticated, isInitialized } = useAuth();

    if (!isInitialized) {
        return <React.Fragment></React.Fragment>;
    }

    if (isAuthenticated) {
        return <Navigate to={`/${location.search}`} />;
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
}

export default GuestGuard
