import { useCallback, useRef } from 'react';

export function useDebounce(callback, delay) {
    const isDebouncing = useRef(false);
    const timerRef = useRef(null);

    const debouncedCallback = useCallback((...args) => {
        if (isDebouncing.current) return;

        callback(...args);
        isDebouncing.current = true;

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            isDebouncing.current = false;
        }, delay);
    }, [callback, delay]);

    return debouncedCallback;
}