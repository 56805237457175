import { useState, useEffect,useRef } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalState } from '../../hooks/useInitialization.js';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp.js';
import PageLoader from '../../components/PageLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

const GalleryPage = () => {
    const { dataApplication } = useGlobalState();
    const { socket, dispatch } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataSlides, setPageDataSlides] = useState()
    const [index, setIndex] = useState(-1);
    const navigate = useNavigate();
    const location = useLocation();
    const zoomRef = useRef(null);

    const handleClick = (index, item) => setIndex(index);

    useEffect(() => {
        if (socket) {
            socket.on('pageDataGallery', (response) => {
                console.log(response)
                let slides = []
                if (response.length > 0) {
                    slides = response.map(({ original, width, height }) => ({
                        src: original,
                        width,
                        height,
                    }));
                }
                setPageData(response);
                setPageDataSlides(slides);
                dispatch({ type: 'gallery', payload: response });
            });

            socket.emit('pageDataGallery', { page: 'gallery' });

            return () => {
                socket.off('pageDataGallery');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <motion.div className="container galleryContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader mb-3">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Galeria</h5></li>
                    </ol>
                </nav>
            </header>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <>
                            <Gallery
                                images={pageData}
                                onClick={handleClick}
                                enableImageSelection={false}
                            />
                            <Lightbox
                                slides={pageDataSlides}
                                open={index >= 0}
                                index={index}
                                close={() => setIndex(-1)}
                                plugins={[Zoom]}
                                zoom={{ ref: zoomRef }}
                            />
                        </>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhuma notificação encontrada</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div >
    )
}

export default GalleryPage