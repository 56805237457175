import { useState, useEffect } from 'react'
import { Button, Col, Row, InputGroup, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faClose, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { useAppState } from '../../hooks/useApp.js'
import { useGlobalState } from '../../hooks/useInitialization.js'
import PageLoader from '../../components/PageLoader.js'
import { cleanUpSpecialChars } from "../../components/Functions.js"
import "react-image-gallery/styles/css/image-gallery.css";

const PointsOfInterest = () => {
    const { dataApplication } = useGlobalState();
    const { socket } = useAppState()
    const [pageData, setPageData] = useState()
    const [pageDataComplete, setPageDataComplete] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { item } = location.state || {};
    const [pageDataItem, setPageDataItem] = useState(item ? item : undefined)

    const search = (data) => {
        if (searchQuery.trim() !== '') {
            const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
            let newData = data.filter(category => {
                const searchableFields = [
                    cleanUpSpecialChars(category.json.title.toLowerCase())];
                return searchableFields.some(field => field.includes(lowercaseQuery));
            })
            return newData
        } else {
            return data
        }
    }

    useEffect(() => {
        if (pageData) {
            let newData = search(pageDataComplete)
            setPageData(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    useEffect(() => {
        if (socket) {
            socket.on('pageDataPoints', (response) => {
                console.log(response)
                if (id && response.id) {
                    setPageDataItem(response);
                } else {
                    setPageData(response);
                    setPageDataComplete(response)
                }
            });
            socket.emit('pageDataPoints', { page: 'interestPoints', id: id });
            // Cleanup listener when component unmounts
            return () => {
                socket.off('pageDataPoints');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <motion.div className="container pointsOfInterestContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
            <header className="pageHeader">
                <Button type="button" className="backButton" onClick={(() => navigate(`/${location.search}`))}>
                    <FontAwesomeIcon icon={faAngleLeft} size={"lg"} />
                </Button>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ms-2">
                        <li className="breadcrumb-item"><h5>Pontos de interesse</h5></li>
                    </ol>
                </nav>
            </header>
            <Row className="mb-3">
                <Col sm={12}>
                    <InputGroup className="mb-3">
                        <motion.input
                            layout
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5 } }}
                            exit={{ opacity: 0 }}
                            className="form-control"
                            placeholder="Pesquisar..."
                            aria-label="search"
                            aria-describedby="search"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                        <AnimatePresence>
                            {searchQuery !== '' && (
                                <motion.div
                                    layout
                                    id="search"
                                    className="input-group-text"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                    exit={{ opacity: 0 }}
                                >
                                    <FontAwesomeIcon className="primaryColorT" icon={faClose} onClick={() => setSearchQuery('')} />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </InputGroup>
                </Col>
            </Row>
            {(!pageData) ? (
                <motion.div className="container pageLoaderContainer" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                </motion.div>
            ) : (
                <>
                    {(Array.isArray(pageData)) && pageData.length > 0 ? (
                        <Row >
                            {pageData.map((item, index) => (
                                <Col sm={6} md={4} lg={3} className="mb-3" key={'PointsOfInterestCard' + index} >
                                    <PointsOfInterestCard item={item} />
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col className="text-center mt-4" sm={12}>
                                <p className="opacity-75">Nenhum ponto de interesse encontrado</p>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </motion.div >
    )
}

export const PointsOfInterestCard = ({ item }) => {
    const { dataApplication } = useGlobalState();

    return (
        <div className="card mb-3 pointsOfInterestCard">
            <div className="card-body">
                {item.collected ? <Badge bg="primary">Coletado</Badge> : <Badge bg="secondary">Não coletado</Badge>}
            </div>
            <div className="card-body py-0">
                <div className={`imageContainer ${item.collected > 0 ? '' : 'not-collected'}`}>
                    <img src={dataApplication.customizacao.server.imageServer + "/plataforma-eventos/" + dataApplication.event_id + "/" + item.json.image} alt="points of interests images"></img>
                </div>
            </div>
            <div className="card-body">
                <div className="pointsOfInterestDetails">
                    <h6 className="fw-bold mb-2">
                        {item.json.title}
                        {item.json.tip && item.json.tip !== '' &&
                            <OverlayTrigger
                                overlay={<Tooltip>{item.json.tip}</Tooltip>}
                            >
                                <FontAwesomeIcon className="iconsHover ms-2" size={'sm'} icon={faQuestionCircle} />
                            </OverlayTrigger>}
                    </h6>
                    <div>
                        <Badge bg="outline-secondary mb-2"> {item.value}</Badge>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PointsOfInterest